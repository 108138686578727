
import { Component, Vue, Prop } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import vMultiselectListbox from "vue-multiselect-listbox";
import draggable from "vuedraggable";

import StartGroup from "../../models/sportevent/StartGroup";
import StartGroupService from "../../services/sportevent/StartGroupService";
import StartBlockService from "../../services/sportevent/StartBlockService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import Competition from "../../models/sporteventCalculation/Competition";
import Starter from "../../models/sportevent/Starter";
import StarterService from "../../services/sportevent/StarterService";
import Person from "../../models/societymgmt/Person";
import Sportevent from "../../models/sportevent/Sportevent";
import SporteventService from "../../services/sportevent/SporteventService";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import { resolve } from "path";

@Component({
  components: {
    Multiselect,
    datetime: Datetime,
    draggable,
    "v-multiselect-listbox": vMultiselectListbox,
  },
})
export default class StarterComponent extends Vue {
  @Prop()
  public startBlockId!: string;

  public sportevent: Sportevent = new Sportevent();
  public startGroup: StartGroup = new StartGroup();
  public allSporteventCompetitions: SporteventCompetition[] = [];
  public allStarter: Starter[] = [];

  public isLoadingStartGroup = true;
  public isLoadingStartBlock = true;
  public isLoadingStarter = true;

  public startersWithoutStartgroup: Starter[] = [];
  public sporteventCompetitions: SporteventCompetition[] = [];
  public isLoadingStartCompetition = true;

  public buttonDisabled = false;

  public dragOptions = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  };

  async mounted(): Promise<void> {
    const currentSportevent = await SporteventService.get();
    if (currentSportevent.id === undefined) {
      return;
    }
    this.allSporteventCompetitions =
      await SporteventCompetitionService.getAll();
    this.sportevent = await SporteventService.get();

    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      this.startGroup = new StartGroup(
        await StartGroupService.get(this.$route.params.id)
      );
      this.isLoadingStartGroup = false;
    } else {
      this.startGroup.startBlock = await StartBlockService.get(
        this.$route.params.startBlockId
      );

      this.startGroup.sportevent.id = this.sportevent.id;

      if (this.$route.query.startgroupcount !== null) {
        this.startGroup.startGroupPosition =
          Number(this.$route.query.startgroupcount) +
          this.startGroup.startBlock.startGroupPositionBeginn;
      }

      this.isLoadingStartGroup = false;
    }
    this.loadStarters();
    this.isLoadingStarter = false;
    this.isLoadingStartBlock = false;
  }

  changeSporteventCompetiton(): void {
    this.loadStarters();
  }

  loadStarters(): void {
    this.isLoadingStarter = true;
    this.allStarter = this.startGroup.starters;
    const fetchStarter = async () => {
      this.isLoadingStarter = true;
      await this.getStarterWithoutStartgroup();

      const selectedIds = this.startGroup.sporteventCompetitions.map(
        ({ id }) => id
      );

      const allStarters = this.allStarter.concat(
        this.startersWithoutStartgroup.filter((starter: Starter) =>
          selectedIds.includes(starter.sporteventCompetition.id)
        )
      );
      this.allStarter = [
        ...new Map(allStarters.map((item) => [item["id"], item])).values(),
      ];
    };

    fetchStarter().then(() => {
      this.isLoadingStarter = false;
      this.updateStarterPositions();
    });
  }

  async changeStarters(): Promise<void> {
    this.isLoadingStartGroup = true;
    this.updateStarterPositions();
    await new Promise((resolve) => setTimeout(resolve, 20));
    this.isLoadingStartGroup = false;
  }

  getCompetitionName(sporteventCompetition: SporteventCompetition): string {
    const competition = new Competition(sporteventCompetition.competition);
    return `${competition.name}`;
  }

  getStarterName(starter: Starter): string {
    const person = new Person(starter.person);
    return `${starter.startsForSociety.shortname} - ${
      starter.sporteventCompetition.competition.name
    } - (${person.getAge()}) ${person.getFullname()}`;
  }

  updateStartnumberMin(): void {
    //console.log(this.startGroup);
    this.updateStarterPositions();
  }

  StartGroupName(): string {
    return this.$store.state.localstore_sportevent.sportevent.startgroupName
      .name;
  }

  async getStarterWithoutStartgroup(): Promise<void> {
    this.isLoadingStartCompetition = true;
    this.startersWithoutStartgroup =
      await StarterService.getAllBySporteventWithoutStartgroup(
        this.$store.state.localstore_sportevent.sportevent.id
      );
    this.startersWithoutStartgroup.forEach((starter: Starter) => {
      if (
        this.sporteventCompetitions.findIndex(
          (sc: SporteventCompetition) =>
            sc.id == starter.sporteventCompetition.id
        ) === -1
      ) {
        const fullSporteventCompetition = this.allSporteventCompetitions.find(
          (sc: SporteventCompetition) =>
            sc.id == starter.sporteventCompetition.id
        );

        if (fullSporteventCompetition !== undefined) {
          this.sporteventCompetitions.push(fullSporteventCompetition);
        }
      }
    });

    if (this.startersWithoutStartgroup.length > 0) {
      this.startersWithoutStartgroup.sort((a, b) => {
        if (a.startsForSociety.name < b.startsForSociety.name) return -1;
        if (a.startsForSociety.name > b.startsForSociety.name) return 1;
        return 0;
      });
    }

    this.sporteventCompetitions.sort((a, b) => {
      if (
        a.competition.name.substring(0, 2) < b.competition.name.substring(0, 2)
      )
        return -1;
      if (
        a.competition.name.substring(0, 2) > b.competition.name.substring(0, 2)
      )
        return 1;
      if (
        a.competition.ageGenderGroup.gender <
        b.competition.ageGenderGroup.gender
      )
        return -1;
      if (
        a.competition.ageGenderGroup.gender >
        b.competition.ageGenderGroup.gender
      )
        return 1;
      if (
        a.competition.ageGenderGroup.ageFrom <
        b.competition.ageGenderGroup.ageFrom
      )
        return -1;
      if (
        a.competition.ageGenderGroup.ageFrom >
        b.competition.ageGenderGroup.ageFrom
      )
        return 1;
      return 0;
    });
    //console.log("sporteventCompetitions", this.sporteventCompetitions);

    this.isLoadingStartCompetition = false;
  }

  updateStarterPositions(): void {
    let position = this.startGroup.startnumberMin;
    const updatePositions = async () => {
      this.startGroup.starters.forEach((starter) => {
        starter.positionInStartgroup = position;
        position++;
      });
    };
    updatePositions();
  }

  async save(handleback = true): Promise<void> {
    this.buttonDisabled = true;
    let isNew = "false";
    if (this.$route.params.id === "new") {
      isNew = "true";
    }

    const startgroupSaved = await StartGroupService.save(this.startGroup);
    this.startGroup = new StartGroup(startgroupSaved);

    this.buttonDisabled = false;
    if (handleback) {
      ToastService.Success(i18n.tc("labels.saved"), "");
      this.$router.replace(
        `/sportevent/startblocks/?editedStartgroupId=${this.startGroup.id}&isNew=${isNew}`
      );
    }
  }

  handleBack(): void {
    this.$router.back();
  }
}
